import React from "react";
import DocumentaryCarousel from "../Components/DocumentaryCarousel";
import DocumentaryList from "../Components/DocumentaryList";
import Media from "react-media";

const DocumentaryPage = () => {
  function importAll(r) {
    let documentary = [];
    r.keys().forEach((item, index) => {
      documentary.push("/images/documentary/" + item.replace("./", ""));
    });
    return documentary;
  }

  const documentary = importAll(
    require.context("../images/documentary/", true, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className="container-fluid  p-0">
      <Media query="(min-width:991px)">
        {(matches) => {
          return matches ? (
            <DocumentaryCarousel documentary={documentary} />
          ) : (
            <DocumentaryList documentary={documentary} />
          );
        }}
      </Media>
    </div>
  );
};

export default DocumentaryPage;
