import { React } from "react";
import { CiMenuBurger } from "react-icons/ci";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useLocation } from "react-router-dom";

export default function Navbar() {
  const location = useLocation();
  return (
    <>
      <nav
        className={`navbar navbar-expand-lg   ${
          location.pathname === "/" ? "fixed-top" : ""
        }`}
      >
        <div className="container mb-5 mt-3 ms-auto me-auto  ">
          <a className="navbar-brand text-white " href="/">
            GWGW GALANOPOULOU
          </a>
          <button
            className="navbar-toggler "
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="menuBurger">
              <CiMenuBurger />
            </span>
          </button>
          <div
            className="collapse navbar-collapse sm"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ms-auto ">
              <li key="1" className="nav-item">
                <a
                  className={`nav-link active dropdown-toggle hover-font ${
                    location.pathname === "/" ? "active" : ""
                  }`}
                  style={
                    location.pathname === "/"
                      ? { color: "white" }
                      : { color: "#CECCCC" }
                  }
                  aria-current="page"
                  href="/"
                >
                  HOME
                </a>
              </li>

              <li key="2" className="nav-item btn-group">
                <a
                  role="button"
                  href="/work"
                  className={`btn nav-link active  hover-font ${
                    location.pathname === "/work" ? "active" : ""
                  }`}
                  style={
                    location.pathname === "/work"
                      ? { color: "white" }
                      : { color: "#CECCCC" }
                  }
                >
                  WORK
                </a>
                <button
                  type="button"
                  className="btn btn-transparent text-white"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <span className="">
                    <MdKeyboardArrowDown />
                  </span>
                </button>
                <ul
                  className="dropdown-menu bg-transparent border-0"
                  aria-labelledby="navbarDropdown"
                >
                  <li key="3">
                    {" "}
                    <a
                      className="dropdown-item hover-font"
                      href="/portraits"
                      style={{ color: "#CECCCC" }}
                    >
                      PORTRAITS
                    </a>
                  </li>
                  <li key="4">
                    <a
                      className="dropdown-item  hover-font"
                      href="/scapes"
                      style={{ color: "#CECCCC" }}
                    >
                      SCAPES
                    </a>
                  </li>
                  <li key="5">
                    <a
                      className="dropdown-item  hover-font"
                      href="/documentary"
                      style={{ color: "#CECCCC" }}
                    >
                      DOCUMENTARY
                    </a>
                  </li>
                  <li key="6">
                    <a
                      className="dropdown-item  hover-font"
                      href="/artwork"
                      style={{ color: "#CECCCC" }}
                    >
                      ARTWORK
                    </a>
                  </li>
                </ul>
              </li>
              <li className="nav-item " key="7">
                <a
                  className={`nav-link active  hover-font ${
                    location.pathname === "/about" ? "active" : ""
                  }`}
                  style={
                    location.pathname === "/about"
                      ? { color: "white" }
                      : { color: "#CECCCC" }
                  }
                  href="/about"
                >
                  ABOUT
                </a>
              </li>
              <li className="nav-item " key="8">
                <a
                  className={`nav-link active  hover-font ${
                    location.pathname === "/contact" ? "active" : ""
                  }`}
                  style={
                    location.pathname === "/contact"
                      ? { color: "white" }
                      : { color: "#CECCCC" }
                  }
                  href="/contact"
                >
                  CONTACT
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
