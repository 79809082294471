import React from "react";
import ScapesCarousel from "../Components/ScapesCarousel";
import ScapesList from "../Components/ScapesList";
import Media from "react-media";

const ScapePage = () => {
  function importAll(r) {
    let scapes = [];
    r.keys().forEach((item, index) => {
      scapes.push("/images/ScapesImage/" + item.replace("./", ""));
    });
    return scapes;
  }

  const scapes = importAll(
    require.context("../images/ScapesImage/", true, /\.(png|jpe?g|svg)$/)
  );

  return (
    <div className="container-fluid  p-0">
      <Media query="(min-width:991px)">
        {(matches) => {
          return matches ? (
            <ScapesCarousel scapes={scapes} />
          ) : (
            <ScapesList scapes={scapes} />
          );
        }}
      </Media>
    </div>
  );
};

export default ScapePage;
