import React from "react";
import HomeCarouselC from "../Components/HomeCarouselC";

const HomePage = () => {
  return (
    <div className="container-fluid  p-0">
      <HomeCarouselC />
    </div>
  );
};

export default HomePage;
