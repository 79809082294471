import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareFacebook } from "@fortawesome/free-brands-svg-icons";
import { faSquareInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <div className="footer fixed-bottom">
      <div className="sb_footer-below">
        <div className="sb_footer-copyright">
          <div className="social-icons-div">
            <a
              href="https://www.facebook.com/gwgw.galanopoulou"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faSquareFacebook}
                className="social-icons"
              />
            </a>
            <a
              href="https://www.instagram.com/gwgw.galanopoulou/"
              target="_blank"
              rel="noreferrer"
            >
              <FontAwesomeIcon
                icon={faSquareInstagram}
                className="social-icons"
              />
            </a>
          </div>
          <p> &copy; {new Date().getFullYear()} Gwgw Galanopoulou</p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
