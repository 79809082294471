import React from "react";
import a3 from "../images/artwork/a3.jpg";
import a2 from "../images/artwork/a2.jpg";
import a1 from "../images/artwork/a1.jpg";
import incirrina1 from "../images/artwork/incirrina1.jpg";
import incirrina2 from "../images/artwork/incirrina2.jpg";

function ArtworkList({ artworks }) {
  return (
    <>
      <div className="container  mt-5 pt-2 pb-5 ">
        <div className="row row-cols-1   g-4 mb-5">
          <div className="col">
            <div className="card ">
              <img
                src={a1}
                className="card-img-top"
                loading="lazy"
                alt="Incirrina lp"
              />
              <div className="card-body">
                <strong className="card-title">
                  Incirrina – 8.15 LP & EP ARTWORK
                </strong>
                <a
                  href="https://incirrina.bandcamp.com/album/815-l-p-full-album"
                  className="btn btn-dark  btn_position"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit Bandcamb
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card ">
              <img
                src={a2}
                className="card-img-top"
                loading="lazy"
                alt="Incirrina lp"
              />
              <div className="card-body">
                <strong className="card-title">
                  Incirrina – Lip Led Scream lp ARTWORK
                </strong>
                <a
                  href="https://incirrina.bandcamp.com/album/lip-led-scream"
                  className="btn btn-dark btn_position"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit Bandcamb
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card ">
              <img
                src={a3}
                className="card-img-top"
                loading="lazy"
                alt="Incirrina Ep"
              />
              <div className="card-body">
                <strong className="card-title">
                  Incirrina – Utter EP ARTWORK
                </strong>
                <a
                  href="https://incirrina.bandcamp.com/album/utter"
                  className="btn btn-dark btn_position"
                  target="_blank"
                  rel="noreferrer"
                >
                  Visit Bandcamb
                </a>
              </div>
            </div>
          </div>{" "}
          <div className="col">
            <div className="card ">
              <img
                src={incirrina2}
                className="card-img-top"
                loading="lazy"
                alt="Incirrina clip"
              />
              <div className="card-body">
                <strong className="card-title">
                  Incirrina - A Little Girl Lost (official VIDEOCLIP)
                </strong>
                <a
                  href="https://www.youtube.com/watch?v=vv7zfBr3ePE"
                  className="btn btn-dark btn_position"
                  target="_blank"
                  rel="noreferrer"
                >
                  Watch Video
                </a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card ">
              <img
                src={incirrina1}
                className="card-img-top"
                loading="lazy"
                alt="Incirrina clip"
              />
              <div className="card-body">
                <strong className="card-title">
                  Incirrina - The Smile (official VIDEOCLIP)
                </strong>
                <a
                  href="https://www.youtube.com/watch?v=VAz53YbO-xE"
                  className="btn btn-dark btn_position"
                  target="_blank"
                  rel="noreferrer"
                >
                  Watch Video
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtworkList;
