import React from "react";
import Media from "react-media";
import PortraitCarousel from "../Components/PortraitCarousel";
import PortraitList from "../Components/PortraitList";

function PortraitPage() {
  function importAll(r) {
    let portraits = [];
    r.keys().forEach((item, index) => {
      portraits.push("../images/portraits/" + item.replace("./", ""));
    });
    return portraits;
  }

  const portraits = importAll(
    require.context("../images/portraits/", true, /\.(png|jpe?g|svg)$/)
  );
  return (
    <div className="container-fluid  p-0">
      <Media query="(min-width:991px)">
        {(matches) => {
          return matches ? (
            <PortraitCarousel portraits={portraits} />
          ) : (
            <PortraitList portraits={portraits} />
          );
        }}
      </Media>
    </div>
  );
}

export default PortraitPage;
