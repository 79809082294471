import React from "react";
import CollazWorkC from "../Components/CollazWorkC";

const WorkPage = () => {
  return (
    <div>
      <CollazWorkC />
    </div>
  );
};

export default WorkPage;
