import React from "react";
import { useLocation } from "react-router-dom";
function Categories() {
  const location = useLocation();
  const categories = [
    {
      url: "/portraits",
      name: "Portraits",
    },
    {
      url: "/scapes",
      name: "Scapes",
    },
    {
      url: "/documentary",
      name: "Documentary",
    },
    {
      url: "/artwork",
      name: "Artwork",
    },
  ];

  return (
    <>
      <div className=" row ms-auto me-auto pt-5">
        <ul className="nav justify-content-center text-color  p-0">
          {categories?.map((item, i) => {
            return (
              <div className="col-2 nav-aligh ">
                <li key={`li-${i}`} className="nav-item ml-5 ">
                  <a
                    href={item.url}
                    key={i}
                    className={`nav-link nav_hover 
                    ${location.pathname === item.url ? "active" : ""}`}
                    style={
                      location.pathname === item.url ? { color: "white" } : null
                    }
                  >
                    {item.name}
                  </a>
                </li>
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
}
export default Categories;
