import React from "react";
import profile from "../images/profile.jpg";
import momus from "../images/MOMus-Photography-Museum-1024x769.jpg";
import lifo from "../images/lifo.png";
import yms from "../images/You-May-Say-Magazine-Issue-2-downloadjpg.jpg";
import lens from "../images/lensculture.jpg";

function AboutList() {
  return (
    <>
      <div className="container p-0">
        <div className="container d-flex justify-content-between">
          <div className="row mt-5 pt-3">
            <div className="col p-0">
              <div className="row  gx-5  gy-5 row-cols  ms-auto me-auto ">
                <div className="col-6 col-md-4">
                  {" "}
                  <p className="lh-base word-wrap text-white">
                    Publications-Partisipations:
                  </p>
                </div>
                <div className="col-sm-6 col-lg-8 ">
                  <div className="row row-cols-1 row-cols-md-3 row-cols-lg-5  g-4 ">
                    <a
                      href="https://www.lifo.gr/blogs/almanac/nyhterines-diadromes"
                      target="_blank"
                      rel="noreferrer"
                      cursor="pointer"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="col">
                        <div className="btn  g-3">
                          <img
                            src={lifo}
                            className="card-img-top"
                            alt="lifo1"
                          />

                          {/* <button className="btn border btn-success">
                            <p className="card-text text-white">VISIT PAGE</p>
                          </button> */}
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.lifo.gr/blogs/almanac/tsigganikos-gamos-stin-sarantaporoy"
                      target="_blank"
                      rel="noreferrer"
                      cursor="pointer"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="col">
                        <div className="btn  g-3">
                          <img
                            src={lifo}
                            className="card-img-top"
                            alt="lifo2"
                          />
                          {/* <button className="btn border btn-success">
                            <p className="card-text text-white">VISIT PAGE</p>
                          </button> */}
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://youmaysayiamadreamer.com/kannavi-ke-sex-mia-schesi-gonimi/"
                      target="_blank"
                      rel="noreferrer"
                      cursor="pointer"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="col">
                        <div className="btn  g-3">
                          <img src={yms} className="card-img-top" alt="yms" />
                          {/* <button className="btn border btn-success">
                            <p className="card-text text-white">VISIT PAGE</p>
                          </button> */}
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://res.momus.gr/el/anthropause-gr"
                      target="_blank"
                      rel="noreferrer"
                      cursor="pointer"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="col">
                        <div className="btn  g-3">
                          <img
                            src={momus}
                            className="card-img-top"
                            alt="momus"
                          />
                          {/* <button className="btn border btn-success">
                            <p className="card-text text-white">VISIT PAGE</p>
                          </button> */}
                        </div>
                      </div>
                    </a>
                    <a
                      href="https://www.lensculture.com/gwgw-galanopoulou"
                      target="_blank"
                      rel="noreferrer"
                      cursor="pointer"
                      style={{ textDecoration: "none" }}
                    >
                      <div className="col">
                        <div className="btn  ">
                          <img src={lens} className="card-img-top" alt="lens" />
                          {/* <button className="btn border btn-success">
                            <p className="card-text text-white">VISIT PAGE</p>
                          </button> */}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutList;
