import React, { useEffect, useState } from "react";
import { GoArrowUp } from "react-icons/go";

function BackToTopButton() {
  const [backToTopButton, setBackToTopButton] = useState(false);

  const scrollToTop = (e) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 1) {
        setBackToTopButton(true);
      } else {
        setBackToTopButton(false);
      }
    });
  }, []);

  return (
    <button
      className={`btn  ${
        backToTopButton ? "scroll_button" : "scroll_button_hide"
      }`}
      role="button"
      onClick={(e) => {
        scrollToTop(e);
      }}
    >
      <span>
        <GoArrowUp />
      </span>
    </button>
  );
}

export default BackToTopButton;
