import React, { useState } from "react";
import Categories from "./Categories";

const ScapesCarousel = ({ scapes }) => {
  const [carouselList, setCarouselList] = useState([]);

  const newCarItems = (e, index) => {
    e.preventDefault();
    for (let i = 0; i < scapes.length; i++) {
      if (i >= index) {
        setCarouselList((carouselList) => [...carouselList, scapes[i]]);
      }
    }
    for (let v = 0; v < scapes.length; v++) {
      if (v < index) {
        setCarouselList((carouselList) => [...carouselList, scapes[v]]);
      }
    }
  };

  const blur = () => {
    setCarouselList([]);
  };
  return (
    <>
      <Categories />

      <div className="container  mt-5  p-0 mb-5">
        <div className="row gwgw gx-4 row-cols-1  row-cols-md-3 row-cols-lg-4 gy-4 ">
          {scapes?.map((port, i) => {
            return (
              <div className="col  pothitos ">
                <a
                  key={`Port-${i}`}
                  href="/"
                  className="btn m-0 p-0"
                  role="button"
                  data-bs-toggle="modal"
                  data-bs-target="#portraitModal"
                  onClick={(e) => newCarItems(e, i)}
                >
                  <img
                    className=" img-fluid  shadow-lg m-1"
                    src={port}
                    loading="lazy"
                    alt="image_portrait"
                  />
                </a>
              </div>
            );
          })}

          <div
            className="modal fade "
            id="portraitModal"
            tabIndex="-1"
            aria-labelledby="portraitModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-xl modal-dialog-centered">
              <div className="modal-content backround_modal ">
                <div className="modal-body">
                  <div
                    id="carouselExampleControls"
                    className="carousel slide"
                    data-bs-ride="carousel"
                    onBlur={() => blur()}
                  >
                    <div className="carousel-inner">
                      {carouselList?.map((car, i) => {
                        return (
                          <div
                            key={`Car-${i}`}
                            className={`carousel-item ${
                              i === 0 ? "active" : ""
                            }`}
                          >
                            <img
                              src={car}
                              className="d-block w-100"
                              alt="image_portrait"
                            />
                          </div>
                        );
                      })}
                    </div>
                    <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="prev"
                    >
                      <span
                        className="carousel-control-prev-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#carouselExampleControls"
                      data-bs-slide="next"
                    >
                      <span
                        className="carousel-control-next-icon"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Next</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ScapesCarousel;
