import React from "react";

function HomeCarouselC() {
  return (
    <>
      <div
        id="carouselExampleFade"
        className="carousel slide carousel-fade "
        data-bs-ride="carousel"
        data-bs-pause="false"
      >
        <div className="carousel-inner">
          <div className="carousel-item carousel-image  bg-img-1 active"></div>
          <div className="carousel-item carousel_image bg-img-2"></div>
          <div className="carousel-item carousel_image bg-img-3"></div>
          <div className="carousel-item carousel_image bg-img-4"></div>
        </div>
      </div>
    </>
  );
}

export default HomeCarouselC;
