import React from "react";

const DocumentaryList = ({ documentary }) => {
  return (
    <>
      <div className="container-fluid mt-5 pb-5">
        <ul className="list-group  pt-2">
          {documentary?.map((documentaryimage, i) => {
            return (
              <li className="list-group-item " key={`ListImage-${i}`}>
                <img
                  src={documentaryimage}
                  className=" img-fluid d-block w-100"
                  alt={`Image-${i + 1}`}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
};

export default DocumentaryList;
