import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScapePage from "./Pages/ScapePage";
import HomePage from "./Pages/HomePage";
import WorkPage from "./Pages/WorkPage";
import AboutPage from "./Pages/AboutPage";
import ContactPage from "./Pages/ContactPage";
import ArtworkPage from "./Pages/ArtworkPage";
import PortraitPage from "./Pages/PortraitPage";
import DocumentaryPage from "./Pages/DocumentaryPage";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";
import BackToTopButton from "./Components/BackToTopButton";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />

        <Routes>
          <Route path="/" element={<Navbar />} />
          <Route index element={<HomePage />} />
          <Route path="work" element={<WorkPage />} />
          <Route path="contact" element={<ContactPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="scapes" element={<ScapePage />} />
          <Route path="portraits" element={<PortraitPage />} />
          <Route path="documentary" element={<DocumentaryPage />} />
          <Route path="artwork" element={<ArtworkPage />} />
          <Route path="about" element={<AboutPage />} />
          <Route path="contact" element={<ContactPage />} />
          {/* </Route> */}
        </Routes>
        <Footer />
      </BrowserRouter>
      <BackToTopButton />
    </div>
  );
}

export default App;
