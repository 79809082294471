import React from "react";
import profile from "../images/profile.jpg";

function ContactPage() {
  return (
    <div>
      <div className="container small_media">
        <div className="row  mb-5 gx-5  gy-5 row-cols   ms-auto me-auto  ">
          <div className="col-6 col-md-4">
            <img className="profile-image " src={profile}></img>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-6 ">
            <div className="text-white">
              <p className="lh-base word-wrap ">Based in Athens GR</p>
              <p className="lh-base word-wrap ">
                To collaborate, purchase a print or license an image please
                contact:
              </p>
              <p className="lh-base word-wrap ">Email: gwgw.uma@gmail.com </p>
              <p>Tel.: 6907585120</p>
              <p className="lh-base word-wrap mt-5">
                &copy; All photographs on this site are the sole intellectual
                property of Gwgw Galanopoulou
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactPage;
