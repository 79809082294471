import React from "react";
import ArtworkCarousel from "../Components/ArtworkCarousel";
import Media from "react-media";
import ArtworkList from "../Components/ArtworkList";

function ArtworkPage() {
  function importAll(r) {
    let artworks = [];
    r.keys().forEach((item, index) => {
      artworks.push("../images/artwork/" + item.replace("./", ""));
    });
    return artworks;
  }

  const artworks = importAll(
    require.context("../images/artwork/", true, /\.(png|jpe?g|svg)$/)
  );
  return (
    <div className="container-fluid  p-0">
      <Media query="(min-width:991px)">
        {(matches) => {
          return matches ? (
            <ArtworkCarousel artworks={artworks} />
          ) : (
            <ArtworkList artworks={artworks} />
          );
        }}
      </Media>
    </div>
  );
}

export default ArtworkPage;
