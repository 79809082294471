import React from "react";
import profile from "../images/profile.jpg";
import AboutList from "../Components/AboutList";

function AboutPage() {
  return (
    <div>
      <div className="container small_media">
        <div className="row  mb-5 gx-5  gy-5 row-cols   ms-auto me-auto  ">
          <div className="col-6 col-md-4">
            <img className="profile-image " src={profile}></img>
          </div>
          <div className="col-sm-6 col-md-4 col-lg-6 ">
            <div>
              <p className="lh-base word-wrap text-white">
                I was born in 1985 in Ilia.I have been involved in photography
                since 2015. I mostly shoot at night, cityscapes, portraits,
                nature , with a digital camera, film and polaroid. I'm inspired
                by the works of Alex Webb, Miguel Rio Branco, Antoine d'Agata,
                Bruno Boudjelal, Dimitris Rapakousis and others..
              </p>
            </div>
          </div>
        </div>
        <AboutList />
      </div>
    </div>
  );
}

export default AboutPage;
