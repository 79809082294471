import React from "react";
import c25 from "../images/portfolio/c25.jpg";
import c45 from "../images/portfolio/c45.jpg";
import c33 from "../images/portfolio/c33.jpg";
import c64 from "../images/portfolio/c64.jpg";
import BackToTopButton from "./BackToTopButton";

import Media from "react-media";

function CollazWorkC() {
  return (
    <>
      <Media query="(min-width:991px)">
        <div className="container  p-0 mb-5 ">
          <div className="row  p-0 ms-auto me-auto">
            <div className="col   p-0">
              {" "}
              <a href="/portraits" className="categories_link">
                <img
                  src={c45}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">PORTRAITS</div>
              </a>
            </div>

            <div className="col p-0">
              <a href="/scapes" className="categories_link">
                <img
                  src={c33}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">SCAPES</div>
              </a>
            </div>
          </div>
          <div className="row ms-auto me-auto p-0">
            <div className="col p-0">
              <a href="/documentary" className="categories_link">
                <img
                  src={c25}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">DOCUMENTARY</div>
              </a>
            </div>
            <div className="col p-0">
              <a href="/artwork" className="categories_link">
                <img
                  src={c64}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">ARTWORK</div>
              </a>
            </div>
          </div>
        </div>
      </Media>
      <Media query="(max-width:991px)">
        <div className="container  p-0 mb-5 mt-5 pt-2">
          <div className="row  p-0 ms-auto me-auto">
            <div className="col p-0">
              <a href="/portraits" className="categories_link">
                <img
                  src={c45}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">PORTRAITS</div>
              </a>
              <a href="/scapes" className="categories_link">
                <img
                  src={c33}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">SCAPES</div>
              </a>

              <a href="/documentary" className="categories_link">
                <img
                  src={c25}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">DOCUMENTARY</div>
              </a>
              <a href="/artwork" className="categories_link">
                <img
                  src={c64}
                  style={{ width: "100%", display: "block" }}
                  className="categories"
                  alt="collaz"
                />
                <div className="text text-white">ARTWORK</div>
              </a>
            </div>
          </div>
        </div>
      </Media>
    </>
  );
}

export default CollazWorkC;
